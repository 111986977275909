<template src='./VerticalCardComingSoon.html'></template>

<script>
export default {
  name: "vertical-card-comingSoon",
  props: {
    message: { type: String },
  },
};
</script>

<style lang="scss" scoped src="./VerticalCardComingSoon.scss"></style>