<template src='./NewsVerticalCard.html'></template>

<script>
export default {
  name: "news-vertical-card",
  props: {
    id: { type: Number },
    name: { type: String },
    date: { type: String },
    description: { type: String },
    image: { type: String },
    descriptionImg: { type: String, default: "Imagen de la noticia" },
    buttonText: { type: String },
  },
  data() {
    return {
      disableButton: "",
    };
  },
  computed: {
    imgUrl() {
      return this.image
        ? this.image
        : "https://firebasestorage.googleapis.com/v0/b/sapiencia-ciudadela.appspot.com/o/Img_default.png?alt=media&token=bee72947-8de9-498d-b907-0043331f997a";
    },
  },
};
</script>

<style lang="scss" scoped src="./NewsVerticalCard.scss"></style>