<template src="./ViewAllNews.html"></template>

<script>
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import VerticalCardComingSoon from "../../components/VerticalCardComingSoon/VerticalCardComingSoon";
import NewsVerticalCard from "../../components/NewsVerticalCard/NewsVerticalCard";
import newsService from "../../services/News";
import ArrobaMedellinNoData from "@/components/ArrobaMedellinNoData/ArrobaMedellinNoData";
import { mapGetters } from "vuex";

export default {
  name: "ViewAllNews",
  components: {
    ArrobaMedellinArrobitaNoData,
    ChooseUniversity,
    VerticalCardComingSoon,
    NewsVerticalCard,
    ArrobaMedellinNoData,
  },
  props: {
    title: {
      type: String,
    },
    TitleNotFound: {
      type: String,
      default: "Noticias",
    },
    notFound: {
      type: String,
      default: "No tienes noticias en éste momento",
    },
    imgDescription: { type: String, default: "Imagen de la noticia" },
  },
  data() {
    return {
      buttonText: "Ver noticia",
      allNews: [],
      myPublishedNews: [],
      otherNews: [],
      role: "",
      newsForInstitution: [],
    };
  },
  created() {
    this.role = this.user.role.name;
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.getInfoNews();
  },
  methods: {
    async getInfoNews() {
      try {
        const objNews = {
          id: this.institutionId,
          newsType: "institution",
          institutionId: this.institutionId,
        };
        this.myPublishedNews = await newsService.listMyNews();
        this.newsForInstitution = await newsService.newsByElement(objNews);
        this.otherNews = await newsService.allUserNotMy(this.institutionId);
        this.allNews = await newsService.listNews();
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidation() {
      return ["Student", "Teacher", "Entrepreneur"].some(
        (a) => a === this.role
      );
    },
    roleValidationTeacherInvestigator() {
      return ["TeacherInvestigator", "Administrator"].some(
        (a) => a === this.role
      );
    },
    roleValidationAdminAnnouncement() {
      return ["AdministratorCall"].some((a) => a === this.role);
    },
    roleValidationUniversity() {
      return ["University"].some((a) => a === this.role);
    },
    calculateCommingSoon() {
      return (quantity, max) => {
        const dif = max - quantity;
        return dif < 0 ? [] : Array(dif);
      };
    },
    ...mapGetters({
      user: "getUser",
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.getInfoNews();
    },
  },
};
</script>

<style scoped lang="scss" src="./ViewAllNews.scss"></style>