<template src="./ArrobaMedellinNoData.html">
</template>

<script>
export default {
  name: 'arroba-medellin-arrobita-no-data',
  props: {
    message: { type: String, default: "Aquí podrás ver tu actividad reciente una vez empieces a navegar por la Ciudadela Universitaria Digital." }
  }
}
</script>

<style lang="scss" scoped src="./ArrobaMedellinNoData.scss">
</style>
