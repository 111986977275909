<template src="./ChooseUniversity.html"></template>

<script>
import idiService from "@/services/Idi";

export default {
  name: "choose-university",
  data() {
    return {
      institutions: [],
      institution: null,
    };
  },
  mounted() {
    this.getListInstitutions();
    this.institution = this.institutionId =
      this.$store.state.User.user.institutionId.id;
  },
  methods: {
    async getListInstitutions() {
      try {
        this.institutions = await idiService.listInstitutions();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("Error: ChooseUniversity/getListInstitutions()", e);
      }
    },
    onChangeInstitution() {
      this.$store.dispatch("fetchInstitutionSelected", this.institution);
    },
  },
};
</script>

<style lang="scss" scoped src="./ChooseUniversity.scss"></style>