import { nodeInstance, phpInstance } from "@/plugins/axios";
import { storage } from "../../plugins/firebase";
import store from "@/store";

const idiService = {};

idiService.createGroup = obj => {
  return nodeInstance.post(`/api/groups`, obj);
};

idiService.updateGroup = (id, obj) => {
  return nodeInstance.patch(`/api/groups/${id}`, obj);
};

idiService.deleteGroup = id => {
  return nodeInstance.delete(`/api/groups/${id}`);
};

idiService.listGroups = () => {
  return nodeInstance.get(`/api/groups/all`);
};

idiService.searchGroups = filter => {
  const params = { filter };
  return nodeInstance.get(`/api/groups`, { params });
};

idiService.listOneGroup = id => {
  return nodeInstance.get(`/api/groups/${id}`);
};

idiService.listRecomendedGroups = () => {
  return nodeInstance.get(`/api/groups/categories/user`);
};

idiService.listOtherGroups = () => {
  return nodeInstance.get(`/api/groups/other/user`);
};

idiService.myGroups = () => {
  return nodeInstance.get(`/api/groups/user`);
};

idiService.userGroupParticipant = id => {
  return nodeInstance.get(`/api/group/participant/validate/${id}`);
};

idiService.postNewParticipant = (idGroup, userId) => {
  return nodeInstance.post(
    `/api/group/participant/${idGroup}?userId=${userId}`
  );
};

idiService.deleteParticipant = obj => {
  const params = {
    userId: obj.userId
  };
  return nodeInstance.delete(`/api/group/participant/${obj.groupId}`, {
    params
  });
};

idiService.deletePublication = id => {
  return nodeInstance.delete(`/api/group/publication/${id}`);
};

idiService.listGroupParticipants = id => {
  return nodeInstance.get(`/api/group/participant/list/${id}`);
};

idiService.searchParticipant = search => {
  const token = store.getters.getToken;
  const params = {
    token,
    search
  };
  return nodeInstance.get(`/api/group/groups/searchParticipant`, { params });
};

idiService.listInstitutions = () => {
  return phpInstance.get(`/institutions`);
};

idiService.listCoordinator = filter => {
  const params = { filter };
  return nodeInstance.get(`/api/coordinators`, { params });
};

idiService.groupParticipantsAndRol = () => {
  return nodeInstance.get(`/api/groups/participant/roles`);
};

idiService.putFile = (folder, subFolder, name, file, groupId, descriptionImg) => {
  const route = !subFolder ? `${folder}` : `${folder}/${subFolder}`;
  return storage
    .child(`${route}/${name}`)
    .put(file)
    .then(async snapshot => {
      const url = await snapshot.ref.getDownloadURL();
      await idiService.createGroupPublication({ url, groupId, name, descriptionImg });
      return url;
    });
};

idiService.getGroupPublications = id => {
  return nodeInstance.get(`/api/group/publication/${id}`);
};

idiService.createGroupPublication = obj => {
  return nodeInstance.post(`/api/group/publication`, obj);
};

idiService.deleteGroupPublication = id => {
  return nodeInstance.delete(`/api/group/publication/${id}`);
};

idiService.sendEmailCoordinator = id => {
  return nodeInstance.get(`/api/group/email/coordinator/${id}`);
};

idiService.myCalls = () => {
  return nodeInstance.get(`/api/announcement/user/list`);
};

idiService.applyResearchProfessor = () => {
  return phpInstance.get(`/user/sendResearchProfessorApplicationEmail`);
};

idiService.countGroups = (institutionId) => {
  return nodeInstance.get(`/api/groups/countGroupsAndRequests/${institutionId}`);
};
idiService.scheduleMeeting = obj => {
  return nodeInstance.post(`/api/meetingGroup`, obj);
};

idiService.searchGroupsByInstitution = (params) => {
  return nodeInstance.post(`/api/groups/searchByInstitution/${params.institutionId}?take=${params.take}&skip=${params.skip}`, { word: params.word });
};

idiService.researchGroupsByInstitution = (params) => {
  return nodeInstance.get(`/api/groups/getByInstitution/${params.institutionId}?take=${params.take}&skip=${params.skip}`);
};

idiService.countAcademicPlant = (institutionId) => {
  return phpInstance.get("/user/countUsersTeacherAndInvestigator/" + institutionId);
};

idiService.listGroupsUser = () => {
  return nodeInstance.get(`/api/groups/user`);
};

idiService.groupRequestList = (params) => {
  return nodeInstance.get(`/api/groupChangeRequest/getByTypeRequest?typeRequest=${params.typeRequest}&institutionId=${params.institutionId}`);
};

idiService.agreeOrRejectGroup = (params) => {
  return nodeInstance.post(`/api/groupChangeRequest/${params.statusRequest}/${params.groupChangeRequestId}`, { tokenPhp: params.token });
};

idiService.groupChangeRequest = obj => {
  return nodeInstance.post(`/api/groupChangeRequest`, obj);
}

idiService.listMyInstitutions = () => {
  return phpInstance.get(`/user/getUserInstitutions`);
};

idiService.getUserTeacherByInstitution = (institutionId) => {
  return phpInstance.get("/user/getUserTeacher/" + institutionId);
};

idiService.getUserTeacherInvestigatorByInstitution = (institutionId) => {
  return phpInstance.get("/user/getUserTeacherInvestigator/" + institutionId);
};

idiService.updateUserCampus = (obj, id) => {
  return phpInstance.put(`/user/updateUserCampus/${id}`, obj);
};

idiService.getRequestsResearchersByInstitution = (institutionId) => {
  return phpInstance.get("/user/getResearchProfessorApplication/" + institutionId);
};

idiService.countGroupParticipantRequest = (groupId) => {
  return nodeInstance.get(`/api/groupParticipantRequest/byGroup/count/${groupId}`);
};

idiService.groupParticipantRequest = (groupId) => {
  return nodeInstance.get(`/api/groupParticipantRequest/byGroup/${groupId}`);
};

idiService.agreeOrRejectGroupParticipantRequest = obj => {
  return nodeInstance.post(`/api/groupParticipantRequest/agreeOrRejectGroupParticipantRequest`, obj);
};

idiService.updateStateResearchProfessorApplication = (obj, userId) => {
  return phpInstance.put(`/user/updateStateResearchProfessorApplication/${userId}`, obj);
};

idiService.validateRemoveRoleTeacherInvestigator = obj => {
  return nodeInstance.post(`/api/groups/validateRemoveRoleTeacherInvestigator`, obj);
};

idiService.sendRequestJoinGroup = obj => {
  return nodeInstance.post(`/api/groupParticipantRequest`, obj);
};

export default idiService;
