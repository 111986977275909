import { nodeInstance } from "@/plugins/axios";

const newsService = {};

newsService.listNews = () => {
    return nodeInstance.get(`/api/news`);
};

newsService.createNews = obj => {
    return nodeInstance.post(`/api/news`, obj);
};

newsService.updateNews = (id, obj) => {
    return nodeInstance.patch(`/api/news/${id}`, obj);
};

newsService.deleteNews = id => {
    return nodeInstance.delete(`/api/news/${id}`);
};

newsService.newsByElement = obj => {
    return nodeInstance.get(`/api/news/byInstitution/${obj.institutionId}`, obj);
};

newsService.listMyNews = () => {
    return nodeInstance.get(`/api/news/myUser`);
};

newsService.getSpecificNews = (id) => {
    return nodeInstance.get(`/api/news/${id}`);
}

newsService.allUserNotMy = (institutionId) => {
    return nodeInstance.get(`/api/news/allUserNotMy/${institutionId}`);
};

export default newsService;